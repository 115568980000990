@import '../../styles/constants';

.page .about {
  max-width: 800px;
  margin: 30px auto;
  padding: 90px 70px 70px;
  text-align: left;
  overflow-y: auto;
  background-color: rgba(255, 255, 255, 0.95);

  h1 {
    text-align: center;
  }

  h2 {
    margin: 50px 0 20px;
  }

  h3 {
    margin: 30px 0 10px;
  }

  p {
    margin-top: 0;
    line-height: 1.4;

    strong {
      font-weight: 600;
    }
  }
}
