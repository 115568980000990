@import '../../styles/constants';

.poem-form {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px 0;

  * {
    font-family: 'Petrona', 'Times New Roman', serif;
    font-size: 24px;
    display: block;
  }

  h2 {
    font-size: 30px;
  }

  label {
    margin-bottom: 8px;
  }

  input,
  select,
  textarea {
    width: 800px;
    margin-bottom: 20px;
  }

  input {
    height: 30px;
    line-height: 30px;
  }

  button {
    display: inline-block;
    margin-right: 20px;
    padding: 5px 10px;
    border-radius: 3px;
    cursor: pointer;
  }
}
