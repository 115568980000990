@import '../../styles/constants.scss';

.poem {
  max-width: 800px;
  margin: 30px auto;
  padding: 90px 70px 70px;
  text-align: left;
  overflow-y: auto;
  background-color: rgba(255, 255, 255, 0.9);

  a {
    position: relative;
    top: 30px;
  }

  &.fade-in {
    animation-duration: 1s;
    animation-name: fadeIn;
    animation-iteration-count: 1;
  }

  .poem-number {
    margin-bottom: 0.5em;
    font-family: 'Petrona', 'Times New Roman', serif;
    font-size: 24px;
    text-align: center;
  }

  .poem-title {
    margin-bottom: 28px;
    font-family: 'Petrona', 'Times New Roman', serif;
    font-size: 42px;
    text-align: center;
  }

  .poem-subtitle {
    margin: -27px 0 28px;
    font-family: 'Petrona', 'Times New Roman', serif;
    font-size: 20px;
    text-align: center;
  }

  .poem-dedication {
    margin: -15px 140px 28px 0;
    font-size: 18px;
    font-style: italic;
    text-align: right;
  }

  .poem-epigram {
    margin: -10px 0 28px 120px;
    font-size: 18px;
    text-align: left;
    font-style: italic;

    em {
      font-style: normal;
    }
  }

  .poem-body {
    font-size: 22px;

    .poem-section-number:first-of-type,
    .poem-section-title:first-of-type {
      margin-top: 1.4em;
    }

    .poem-section-number,
    .poem-section-title {
      font-size: 18px;
      font-style: italic;
      margin-top: 3em;
    }

    .poem-section-number + .poem-section-title {
      margin-top: 3px;
      margin-bottom: 3px;
    }

    .poem-line {
      margin-left: 5em;
      line-height: 1.4em;
      text-indent: -5em;
      white-space: pre-wrap;

      span {
        width: 2em;
        display: inline-block;
      }
    }
  }

  @include for-phone-only {
    margin: 0;
    padding: 30px 20px;

    .poem-number {
      font-size: 16px;
    }

    .poem-title {
      font-size: 28px;
      font-weight: bold;
    }

    .poem-body {
      font-size: 20px;

      .poem-line {
        margin-left: 1em;
        text-indent: -1em;

        span {
          width: 1em;
        }
      }
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
