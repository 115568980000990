@import '../../styles/constants';

nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 40px;
  width: calc(100% - 100px);
  overflow: hidden;

  ul {
    display: flex;
    flex-direction: row;
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      color: rgba(255, 255, 255, 0.6);

      a {
        margin-left: 30px;
        font-family: 'Raleway', Tahoma, Helvetica, sans-serif;
        font-size: 18px;
        line-height: 40px;
        color: inherit;
        text-decoration: none;

        &:hover {
          color: #fff;
        }
      }
    }
  }

  .main-navigation {
    flex: 1;
    min-width: 450px;

    li:first-of-type {
      font-weight: 700;
      color: #fff;
    }
  }

  .next-previous-navigation {
    flex-basis: 190px;
    justify-content: flex-end;

    .previous-poem,
    .next-poem {
      margin: 0 15px;
      text-decoration: none;
      white-space: nowrap;

      span {
        height: 40px;
      }
    }

    .previous-poem {
      text-align: left;
    }

    .next-poem {
      text-align: right;
    }
  }

  @include for-phone-only {
    width: 100%;

    .main-navigation {
      .desktop {
        display: none;
      }
    }
    .next-previous-navigation {
      display: none;
    }
  }
}
