@import '../../styles/constants';

.table-of-contents {
  max-width: 800px;
  margin: 30px auto;
  padding: 50px 60px 80px 100px;
  color: black;
  text-align: left;
  background-color: rgba(255, 254, 250, 0.95);

  h2 {
    margin: 30px 0 65px;
    font-size: 48px;
    text-align: center;
  }

  h3.section-title {
    font-family: 'Petrona', 'Times New Roman', serif;
    font-size: 30px;
    margin: 30px 0 10px 0;
    padding-bottom: 0;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  ul {
    list-style: none;
    margin: 0;
    padding-left: 15px;

    li {
      font-family: 'Raleway', Tahoma, Helvetica, sans-serif;
      font-size: 18px;
      line-height: 24px;

      .toc-numeral {
        display: inline-block;
        width: 70px;
        margin-left: -80px;
        padding-right: 10px;
        float: left;
        line-height: 33px;
        text-align: right;
        color: #666;
      }

      .toc-title {
        display: inline-block;
        font-size: 22px;
        line-height: 30px;
      }

      .toc-title-french {
        display: block;
        margin: 0 0 2px 20px;
        font-style: italic;
      }
    }
  }

  @include for-phone-only {
    margin: 0 auto;
    padding-left: 70px;
    padding-right: 30px;

    h2 {
      margin: 0 0 30px -30px;
      line-height: 1;
    }
  }
}
