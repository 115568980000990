@import './styles/normalize.scss';
@import url('https://fonts.googleapis.com/css?family=Petrona|Raleway:400,700&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: 'Petrona', 'Times New Roman', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
