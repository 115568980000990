@import '../../styles/constants';

.page {
  position: relative;
  height: calc(100% - 40px);
  margin: 0 100px 0 0;
  background-color: #ffffff;
  background-repeat: repeat-x;
  background-position: bottom;

  &.about {
    background-color: rgba(253, 253, 247, 1);
    background-image: url('../../assets/_yellow-orange50.png');
  }
  &.afterword {
    background-color: #0c395d;
    background-image: url('../../assets/whiteForBlue.png');
  }

  &.poems {
    background-color: #fbf8fb;
    background-image: url('../../assets/_flowers40.png');
  }

  &.toc {
    background-color: rgba(255, 250, 235, 1);
    background-image: url('../../assets/poppies.png');
  }

  .simplebar-content {
    height: 100%;
  }
  .simplebar-scrollbar:before {
    background-color: $_PURPLE;
  }

  @include for-phone-only {
    margin: 0;
    background-image: none !important;

    .simplebar-content {
      height: auto;
    }
  }
}
