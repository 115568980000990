@import './styles/constants.scss';

.app {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $_PURPLE;
  background-image: url('./assets/vine_pattern.svg');
  background-size: 200px 500px;
  background-repeat: repeat-y;
  background-position: top 0 right -100px;
  overflow: hidden;

  @include for-phone-only {
    background-position: top 0 right -150px;
  }
}
